<template>
  <div class="container-fluid container-md px-0 px-md-5">
    <div class="row text-center mt-1">
      <div class="col-12">
        <h3>{{ incentiveCategory.category_name }}</h3>
      </div>
    </div>
    <div
      class="row pb-3"
      v-for="(incentive, idx) in incentiveCategory.incentives"
      :key="idx"
    >
      <div class="col-12">
        <Incentive :incentive="incentive" />
      </div>
    </div>
  </div>
</template>

<script>
const Incentive = () => import("Components/Incentives/Incentive");

export default {
  props: {
    incentiveCategory: Object,
  },
  components: {
    Incentive,
  },
};
</script>

<style lang="scss" scoped>
h3 {
 font-family: $primary-header-font-family;
 font-weight: 700;
 font-size: 1.75rem;
 color: white;
}
</style>